.p-about {
  >div {
    padding-bottom: 16px;

    &:last-of-type {
      padding: 24px;
    }
  }

  img {
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
  }
}
