@import "./styles/mixins";
@import "./styles/values";

@font-face {
  font-family: "Philosopher";
  src: url("./fonts/Philosopher-Bold.ttf");
}

@font-face {
  font-family: "Pixel";
  src: url("./fonts/PressStart2P-Regular.ttf");
}

@font-face {
  font-family: "Syne Mono";
  src: url("./fonts/SyneMono-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include scrollbar;

  button {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    color: $color;
    font-size: $font-size;
    font-weight: 900;
  }

  a {
    display: inline-block;
    color: snow;
    text-decoration: none;
    @include click;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0 0 8px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: $font-size;
    line-height: 1.4em;
  }

  code {
    background-color: $scoreboard-bg;
    color: orange;
    padding: 1px 3px;
    border-radius: 4px;
    margin: 0;
  }

  small {
    margin: 0;
  }
}

* {
  box-sizing: border-box;
}
