@import "../../styles/colors";
@import "../../styles/values";
$padding: 12px;

.c-input {
	display: flex;
	gap: $flex-gap;
	justify-content: center;
	align-items: center;

	@include mobile {
		flex-direction: column;
		gap: $flex-gap-mobile;
	}

	>* {
		width: calc(50% - ($flex-gap/2));

		@include mobile {
			width: 100%;
		}
	}

	&-label {
		display: flex;
		flex-direction: column;
		gap: 3px;
		justify-content: center;
		align-items: flex-end;
		flex-grow: 1;

		@include mobile {
			align-items: center;
		}
	}

	input {
		padding: $padding;
		box-sizing: border-box;
		display: flex;
		flex-grow: 1;
		font-weight: 900;
		font-size: 16px;
		font-family: 'Courier New', Courier, monospace;
		color: $bg-color1;
		background-color: darken(darkslateblue, 16%);
		border-radius: 9px;
		border-style: outset;
		border-width: 5px;
		border-color: darkslateblue;

		&:active,
		&:focus {
			border-style: inset;
		}

		@include mobile {
			text-align: center;
			padding: calc($padding / 2);
		}

		&:focus {
			outline-width: 0;
		}
	}
}
