@import "../../../styles/mixins";
@import "../../../styles/values";
@import "../../../styles/colors";

.p-games {
	&-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 16px;

		a {
			display: flex;

			.game-card {
				@include gel-button($active-border: inset);
				justify-content: flex-start;
				max-width: 288px;

				h6 {
					padding: 12px 8px 8px;
					width: 100%;
				}

				>div {
					font-size: 14px;
					border-top: 2px solid $bg-color1;
					padding: 8px 8px 12px;
					width: 100%;
				}
			}
		}
	}

	&-nav {
		@include navbar;
		@include gel-button;
		align-items: flex-start;

		@include mobile {
			align-items: center;
		}

		a {
			@include gel-button($active-border: inset);
			padding: 8px 16px;
		}
	}

	&-rps {
		font-family: 'Courier New', Courier, monospace;

		&-content {

			button {
				height: 96px;
				width: 100px;
				padding: 0;
				font-size: 12px;
				background-color: beige;
				border-width: 12px 16px 16px 12px;
				border-radius: 12px;
				border-color: rgb(240, 240, 203);
				box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.5);
				@include click;

				&.clicked {
					border-style: inset;
				}
			}

			.scoreboard {
				display: inline-block;
				margin: 0 auto 8px;
				width: 100%;
				max-width: 512px;
				background-color: $scoreboard-bg;
				border: 4px solid black;
				border-radius: 4px;
				box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.5);

				&-title {
					border-bottom: 1px solid $scoreboard-line;
				}

				&-info {
					display: flex;
					width: 100%;

					&:nth-of-type(1) {
						border-bottom: 1px solid $scoreboard-line;
					}

					&:nth-of-type(2) {
						border-top: 1px solid $scoreboard-line;
					}

					>div {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 50%;

						&:nth-of-type(odd) {
							justify-content: end;
							padding-right: 8px;
							border-right: 1px solid $scoreboard-line;

							@include mobile {
								align-items: flex-end;
								flex-direction: column;
							}
						}

						&:nth-of-type(even) {
							justify-content: start;
							padding-left: 8px;
							border-left: 1px solid $scoreboard-line;

							@include mobile {
								align-items: flex-start;
								flex-direction: column-reverse;
							}
						}
					}
				}

				.battlefield {
					display: flex;
					align-items: center;
					border-top: 2px solid $scoreboard-line;

					>div {
						width: 50%;
						margin: 0 auto;
						padding: 4px 0;

						img,
						.weapon-placeholder {
							width: 48px;
							height: 48px;
							display: block;
							margin: 0 auto;
						}

						.weapon {
							font-size: 48px;
							font-weight: bold;

							@include mobile {
								font-size: 24px;
							}
						}
					}

					.player {
						border-right: 1px solid $scoreboard-line;

						.weapon {
							color: lawngreen;
						}
					}

					.computer {
						border-left: 1px solid $scoreboard-line;

						.weapon {
							color: darkorange;
						}
					}
				}
			}

			.history {
				display: flex;
				border-top: 2px solid $scoreboard-line;

				>div {
					display: flex;
					width: 48px;

					&.player {
						justify-content: flex-end;
						border-right: 1px solid $scoreboard-line;
					}

					&.computer {
						justify-content: flex-start;
						border-left: 1px solid $scoreboard-line;
					}

					img {
						width: 36px;
						height: 36px;
					}
				}
			}
		}

		.history {
			padding-top: 16px;

			>div {
				display: inline-block;
				border: 4px solid black;
				border-radius: 4px;
				margin: 0 16px;
				background-color: $scoreboard-bg;
				vertical-align: top;

				>div {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48px;
					height: 48px;
					border-bottom: 2px solid $scoreboard-line;

					&:last-of-type {
						border-bottom: none;
					}

					&:first-of-type {
						border: 2px dashed gold;
					}

					img {
						width: 36px;
						height: 36px;
					}
				}
			}
		}
	}

	&-popups {
		$width: 720px;

		&-caption {
			$height: 80px;

			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;
			height: calc($height - 8px);
			width: calc($width - 16px);
			margin: -$height auto 0;
			font-family: "Pixel";
			background-color: chocolate;
			border: ridge 8px maroon;
			z-index: 12;

			div {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}

			button {
				@include gel-button($active-border: inset);
				background-color: saddlebrown;
				border-color: saddlebrown;
			}
		}

		img {
			position: relative;
			max-width: 100%;
			width: $width;
			z-index: 1;
		}
	}
}
