@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/values";

.p-color-pick {
	width: 100%;
	display: flex;
	flex-grow: 100;
	flex-direction: column;

	.c-color-picker {
		position: relative;
		padding: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		&-background {
			display: none;
		}

		&-content {
			border: none;
			border-radius: 0;
			max-width: none;
			flex-grow: 1;
		}
	}
}
