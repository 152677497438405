@import "../../styles/values";
@import "../../styles/mixins";

.p-home {
	position: relative;
	width: 100%;

	.space {
		position: relative;
		padding: 64px 0;
		background-color: black;
		background: linear-gradient(to bottom,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.7) 8%,
				rgba(0, 0, 0, 1) 13%,
				rgba(0, 0, 0, 1) 87%,
				rgba(0, 0, 0, 0.7) 92%,
				rgba(0, 0, 0, 0) 100%,
			);
		width: 100%;
		overflow-x: auto;
		@include scrollbar;

		.paused {
			animation-play-state: paused !important;
		}

		.stars {
			overflow: hidden;
			position: absolute;
			width: 100%;
			min-width: 768px;
			height: 640px;
			top: 0;
			left: 0;
			z-index: $z-stars;

			.star {
				position: absolute;
				width: 1px;
				height: 1px;
				background-color: snow;
				z-index: $z-star;
			}
		}

		.orbit {
			position: relative;
			width: 768px;
			height: 512px;
			margin: 0 auto;

			.earth {
				position: relative;
				clip-path: circle(184px at center);
				overflow: hidden;
				width: 512px;
				height: 512px;
				margin: 0 auto;
				z-index: $z-earth;

				&-globe {
					margin: 0 auto;
					background: url("../../assets/earth-map.png");
					width: 512px;
					height: 256px;
					background-size: 1024px 512px;
					animation: spin-earth 24s linear infinite;
					overflow: hidden;
				}

				&-globe-north {
					clip-path: circle(187px at 50% 87.5%);
					background-position-y: 0px;
					transform: perspective(100px) rotateX(6deg);
					transform-origin: bottom;
				}

				&-globe-south {
					clip-path: circle(187px at 50% 12.5%);
					position: relative;
					top: -1px;
					background-position-y: -256px;
					transform: perspective(100px) rotateX(-6deg);
					transform-origin: top;
				}

				&-shadow {
					position: absolute;
					top: 0;
					left: 0;
					width: 512px;
					height: 512px;
					background: radial-gradient(circle at center,
							rgba(0, 0, 0, 0) 0%,
							rgba(0, 0, 0, 0.1) 34%,
							rgba(0, 0, 0, 0.6) 52%,
							rgba(0, 0, 0, 0.9) 68%,
						);
					background-position-x: -48px;
					background-position-y: -48px;
					animation: season 8760s linear infinite;
				}

				&-outline {
					position: absolute;
					top: 0;
					left: 0;
					width: 512px;
					height: 512px;
					background: radial-gradient(circle at center,
							rgba(0, 0, 0, 0) 0%,
							rgba(0, 0, 0, 0) 50%,
							rgba(0, 0, 0, 0.1) 51%,
							rgba(0, 0, 0, 0.6) 55%,
						);
				}
			}

			.moon {
				position: absolute;
				top: 216px;
				left: 0;
				overflow: hidden;
				clip-path: circle(46px at center);
				width: 128px;
				height: 128px;
				margin: 0 auto;
				z-index: $z-moon-front;
				animation: orbit 160s linear infinite;
				filter: blur(0.5px);

				&-globe {
					margin: 0 auto;
					background: url("../../assets/moon.png");
					width: 128px;
					height: 128px;
					background-size: 256px 128px;
					transform: rotate(-5deg);
					animation: spin-moon 21s linear infinite;
				}

				&-shadow {
					position: absolute;
					top: 0;
					left: 0;
					width: 128px;
					height: 128px;
					background: radial-gradient(circle at center,
							rgba(0, 0, 0, 0) 0%,
							rgba(0, 0, 0, 0.1) 35%,
							rgba(0, 0, 0, 0.9) 69%,
							rgba(0, 0, 0, 0.9) 100%,
						);
					background-position-x: -12px;
					background-position-y: -6px;
				}
			}

			@keyframes orbit {
				0% {
					transform: translate(0, 0);
					z-index: $z-moon-front;
				}

				50% {
					transform: translate(640px, -48px);
					z-index: $z-moon-front;
				}

				50.1% {
					z-index: $z-moon-back;
				}

				100% {
					transform: translate(0, 0);
					z-index: $z-moon-back;
				}
			}

			@keyframes spin-moon {
				0% {
					background-position-x: 0;
				}

				100% {
					background-position-x: 256px;
				}
			}

			@keyframes spin-earth {
				0% {
					background-position-x: 0;
				}

				100% {
					background-position-x: 1024px;
				}
			}

			@keyframes season {
				0% {
					background-position-y: -48px;
				}

				50% {
					background-position-y: 48px;
				}

				100% {
					background-position-y: -48px;
				}
			}
		}
	}
}
