@import "../../../styles/mixins";
@import "../../../styles/values";

.p-recipes {
	&-nav {
		@include navbar;
		@include gel-button;
		align-items: flex-start;

		@include mobile {
			align-items: center;
		}

		a {
			@include gel-button($active-border: inset);
			padding: 8px 16px;
		}
	}

	&-list {
		display: inline-block;
		text-align: left;

		ul {
			padding: 0;
			margin: 0;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-grow: 1;
			align-content: space-between;
			justify-content: center;

			a {
				display: flex;
				flex-grow: 1;

				li {
					display: flex;
					flex-grow: 1;
					text-align: center;
					padding: 16px 32px;
					@include gel-button($active-border: inset);
				}
			}
		}
	}

	&-detail {
		&-intro {
			@include gel-button;
			background: linear-gradient(to right, $sub-bg-color1, $sub-bg-color2);
			color: $bg-color2;
			padding: 24px 16px;
			margin-bottom: 16px;

			h4 {
				padding: 0;
			}

			h6 {
				padding: 8px 0 0;
			}
		}

		&-ingredients {
			width: 100%;

			>ol {
				width: 100%;
				padding: 0;
				margin: 0 0 8px;
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
				gap: 8px;

				&:last-of-type {
					margin-bottom: 16px;
				}

				>li {}
			}
		}

		&-steps {
			text-align: left;
			width: 100%;

			>ol {
				width: 100%;
				padding: 0;
				margin: 0;
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
				gap: 16px;

				>li {
					display: flex;
					list-style-type: none;
					width: calc(50% - 8px);

					@include mobile {
						width: 100%;
					}
				}
			}

			div {
				img {
					max-width: 360px;
				}
			}
		}
	}
}
