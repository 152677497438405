@import "../../../styles/colors";
@import "../../../styles/mixins";
@import "../../../styles/values";

.p-qr-code {
	display: flex;
	flex-direction: column;
	gap: $flex-gap;

	h1 {
		@include gel-button();
		padding: 8px;
		border-color: darkslateblue;
		background-color: darken(darkslateblue, 15%);
		margin: 0 auto;
	}

	.inputs {
		display: flex;
		flex-direction: column;
		gap: $flex-gap;

		span {
			display: inline-block;
		}

		.use-lowest-version {
			cursor: pointer;
			@include gel-button($gb-bg-color: $scoreboard-bg, $gb-border: $scoreboard-line, $active-border: inset);
			border-width: 3px;
			padding: 3px;

			.checkbox {
				height: 18px;
				width: 18px;
				border: 1px solid orange;
				border-radius: 4px;
				margin-right: 3px;

				div {
					display: flex;
					justify-content: center;
					align-content: center;
					flex-wrap: wrap;
					height: 100%;
				}
			}
		}

		.toggles {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
		}
	}

	.info {
		@include gel-button();
		padding: 8px;
		margin: 0 auto;
		border-color: darkslateblue;
		background-color: darken(darkslateblue, 15%);

		.button {
			@include gel-button($active-border: inset);
			border-color: darkslateblue;
			background-color: darkslateblue;
			display: inline-block;
			width: auto;
			padding: 4px 12px;
			margin: 0 auto;
		}
	}

	.qr-code {
		canvas {
			max-width: 100%;
			// make sure height is same as width by subtracting the page padding
			max-height: calc(100vw - $page-padding);
		}
	}
}
