@import "../../styles/values";
@import "../../styles/colors";
@import "../../styles/mixins";

.c-popup {
	&-selector {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
		padding: calc($nav-height + 6px + 6px) 16px;

		&-background {
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.618);
			position: fixed;
			z-index: 1;
		}

		&-content {
			@include gel-button;
			@include scrollbar;
			z-index: 2;
			width: 100%;
			max-width: $bp-mobile;
			padding: 0;
			margin: 0;
			max-height: calc(100% - ($nav-height * 4));
			justify-content: flex-start;
			overflow-y: scroll;

			option {
				width: 100%;
				cursor: pointer;
				padding: 16px 8px;
				flex-shrink: 0;
				color: white;
				font-weight: 900;
				text-shadow: 1px 2px 2px black, 1px -2px 2px black, -1px 2px 2px black, -1px -1px 2px black;

				&:nth-of-type(odd) {
					background-color: $bg-block2;
				}

				&.selected {
					font-weight: 900;
					background: linear-gradient(135deg, indigo, 22%, darkgoldenrod 33%);
				}
			}
		}
	}

	&-css-colors {
		.c-popup-selector-content {
			flex-direction: row;
			flex-wrap: wrap;

			option {
				justify-content: center;
				flex: 1 1 0;

				&.selected {
					background: none;
					border: 6px solid red;

					@keyframes css-selected {
						0% {
							border-color: hsl(0, 100%, 50%);
						}

						16.67% {
							border-color: hsl(60, 100%, 50%);
						}

						33.33% {
							border-color: hsl(120, 100%, 50%);
						}

						50% {
							border-color: hsl(180, 100%, 50%);
						}

						66.67% {
							border-color: hsl(240, 100%, 50%);
						}

						83.33% {
							border-color: hsl(300, 100%, 50%);
						}

						100% {
							border-color: hsl(360, 100%, 50%);
						}
					}

					animation: css-selected 5s linear infinite;
				}
			}
		}
	}
}
