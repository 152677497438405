// main
$color: cornsilk;
$bg-color1: lightseagreen;
$bg-color2: darkslategray;
$bg-block1: mediumseagreen;
$bg-block2: seagreen;
$border-color: lightseagreen;
$active-border: lime;

$scrollbar-track: palegreen;
$scrollbar-thumb: seagreen;

$sub-bg-color1: lightgreen;
$sub-bg-color2: darkseagreen;

$scoreboard-bg: rgb(48, 48, 48);
$scoreboard-line: rgb(64, 64, 64);
