@import "../../styles/values";
@import "../../styles/colors";
@import "../../styles/mixins";

.c-color-picker {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	padding: calc($nav-height + 6px + 6px) 16px;

	&-background {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.618);
		position: fixed;
		z-index: 1;
	}

	&-content {
		z-index: 2;
		width: 100%;
		max-width: $bp-mobile;
		@include gel-button;
		@include scrollbar;
		padding: 16px 16px 18px;
		margin: 0;
		overflow-y: auto;
		max-height: 100%;

		&-container {
			display: block;

			h5 {
				mix-blend-mode: difference;
				background-color: dimgray;
				padding: 4px 8px;
				border-radius: 9px;
				border: 4px solid snow;
			}

			.c-color-picker {
				&-chart {
					position: relative;
					cursor: crosshair;
					margin: 0 auto;
					display: inline-block;

					>div {
						position: absolute;
						top: 0;
						width: 256px;
						height: 256px;

						&.c-color-picker-chart {
							&-color {
								position: relative;
							}

							&-black {
								background: linear-gradient(to top, black, transparent)
							}

							&-white {
								background: linear-gradient(to right, white, transparent)
							}

							&-pin {
								mix-blend-mode: difference;
								width: 1px;
								height: 1px;
								display: flex;
								justify-content: center;
								align-items: center;
								z-index: 99;
								color: beige;

								div {
									font-size: 20px;
								}
							}
						}
					}
				}

				&-slider {
					cursor: pointer;
					width: 0;
					height: 24px;
					background: linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00);
					z-index: 2;
					outline: 2px solid mistyrose;
					border-radius: 6px;
					margin: 0 auto;

					&-tag {
						position: relative;
						height: 100%;
						width: 7px;
						border-radius: 6px;
						outline: 3px solid floralwhite;
						margin-left: 72px;
						z-index: 99;
					}
				}

				&-inputs {
					padding: 16px 16px 0;
					display: flex;
					flex-direction: column;

					.c-input {
						@include mobile {
							gap: 0;
							flex-direction: row;

							>* {
								width: calc(50% - ($flex-gap/2));
							}
						}

						&-label {
							@include gel-button;
							align-items: flex-end;
							padding: 0 3px 1px;

							@include mobile {
								padding: 0;
								align-items: center;

								small {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

.c-color-picker-toggle {
	border-width: 4px;
	display: inline-block;
	padding: 0px;
	border-color: darkslateblue;

	&-container {
		@include gel-button($active-border: inset);
		background-color: darkslateblue;
		border-width: 7px;
		border-color: darkslateblue;
		margin: 0 auto;
		width: 100%;
		height: 100%;
		width: calc($nav-height - 8px);
		height: calc($nav-height - 28px);

		&-dot {
			width: 100%;
			height: 100%;
		}
	}
}
