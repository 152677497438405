@import "./styles/mixins";
@import "./styles/values";
@import "./styles/colors";

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  font-size: $font-size;
  color: $color;
  background: $bg-color2;
  background: linear-gradient(to right, $bg-color1, $bg-color2);
  @include no-select;

  .flex-grow {
    display: flex;
    flex-grow: 1;
  }

  .page {
    width: 100%;
    max-width: $bp-tablet;
    margin: 0 auto;
    padding: $page-padding;
    text-align: center;

    @include mobile {
      padding: $page-padding $page-padding-mobile;
    }
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }
}
