@import "../../styles/mixins";

.c-footer {
  @include gel-button;
  font-family: 'Philosopher', sans-serif;
  font-weight: 700;
  width: 100%;
  min-height: 64px;
  padding: 16px;
  background: linear-gradient(to right, $bg-block1, $bg-block2);
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.25);

  >div {
    @include gel-button;
    padding: 16px;
    flex-direction: column;

    @include desktop {
      min-width: 68.1%;
    }

    >div {
      padding-bottom: 8px;

      &:last-of-type {
        font-size: 20px;
        font-weight: 900;
        padding-bottom: 0;
      }
    }
  }
}
